<template>
    <div class="add-new-empoyee" v-if="ocr">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.ocrUpdate')"
            :title="'Update OCR - ' + ocr.referenceNumber"
            @submitPressed="submitPressed"
        />
        <b-card no-body>
            <b-tabs v-model="tabIndex" card content-class="mt-3">
                <b-tab :title="$t('forms.overview')" active>
                    <ocr-form
                        v-if="ocr"
                        :ocrObject="ocr"
                        :action="action"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab :title="$t('forms.files')">
                    <files-form
                        :resource="$Ocrs"
                        :resource-id="$route.params.id"
                        :file-tags="['files']"
                        :can-upload="canUpload()"
                    />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OcrForm from "./components/OcrFormEdit.vue";
import FilesForm from "@/components/Files/FilesForm.vue";
import {getUserData} from '@/auth/utils'

export default {
    components: {
        TitleBar,
        OcrForm,
        FilesForm
    },
    data() {
        return {
            tabIndex: 0,
            ocr: null,
            action: null,
            customerList: null,
        };
    },
    created() {
        if (this.$route.query.files === 'true') {
            this.tabIndex = 1
        }
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Ocrs.getResource({id}).then((response) => {
                this.ocr = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
        canUpload() {
            let userData = getUserData()
            if (userData.role === 'ROLE_ADMIN') {
                return true
            }
            if(this.ocr && this.ocr.employee.id == userData.id) {
                return true
            }
            return false

        }
    },
};
</script>
